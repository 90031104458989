/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ServicesVideo from '../video/services.mp4';
import VideoPoster from '../images/poster-services.jpg';
import Layout from '../components/Layout/Layout';
import ContentCard from '../components/ContentCard/ContentCard';

export const pageQuery = graphql`
  query ServicePageQuery {
    allday: file(relativePath: { eq: "service-img-24x7@2x.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    oneteam: file(relativePath: { eq: "service-img-one-team@2x.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    holistic: file(relativePath: { eq: "service-img-holistic@2x.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    care: file(relativePath: { eq: "service-img-care@2x.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const ServicePage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="Services | Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />

      {/* HERO */}
      <Container>
        <Row>
          <Col>
            <div className="ratio ratio-16x9">
              <video poster={VideoPoster} controls>
                <source src={ServicesVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>
      </Container>
      <section className="bg-gray py-5">
        <Container>
          <Row className="text-center">
            <Col>
              <h2 className="text-primary fw-bold fs-2">Our Promise To You</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="py-3 py-md-5">
        <ContentCard
          featuredImage={data.allday}
          imageText="24/7 Access"
          title="ALWAYS WITH YOU"
          description="We are one phone call away to help you when you need it"
        />
      </Container>

      <section className="bg-gray py-5">
        <Container>
          <ContentCard
            variant="row-reverse"
            featuredImage={data.oneteam}
            imageText="Personalized Care Team"
            title="ONE TEAM"
            description="One dedicated care team that includes
            doctors, nurses, psychiatrists, and
            support staff"
          />
        </Container>
      </section>

      <Container className="py-5">
        <ContentCard
          featuredImage={data.holistic}
          imageText="Whole Person Care"
          title="HOLISTIC APPROACH"
          description="We combine medical treatment, behavioral health services, and social determinants of health, like food, transportation, and other support services"
        />
      </Container>

      <section className="bg-gray py-5">
        <Container>
          <ContentCard
            variant="row-reverse"
            featuredImage={data.care}
            imageText="Care at Home"
            title="CONCIERGE CARE WITHOUT THE FARE"
            symbol="TM"
            description="Reduce your stress; Our doctors and nurses come to you, where you live"
          />
        </Container>
      </section>
    </Layout>
  );
};

export default ServicePage;
