import React from 'react';
import { string, shape, node, oneOf } from 'prop-types';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const propTypes = {
  variant: oneOf(['row', 'row-reverse']),
  featuredImage: shape({}).isRequired,
  imgAlt: string.isRequired,
  title: string.isRequired,
  description: node,
  imageText: string,
};

const defaultProps = {
  variant: 'row',
  description: null,
  imageText: null,
};

const ContentCard = ({
  variant,
  featuredImage,
  imageText,
  imgAlt,
  title,
  symbol,
  description,
}) => {
  return (
    <Row
      className={`justify-content-center align-items-center py-5 flex-${variant}`}
    >
      <Col md={5} lg={3} className="position-relative">
        {imageText && (
          <div className="floating-card-text position-absolute zindex-1 bg-primary p-3 rounded-3 mb-1 shadow-primary">
            <p className="m-0 text-white text-center">{imageText}</p>
          </div>
        )}

        <GatsbyImage
          image={getImage(featuredImage)}
          alt={imgAlt}
          placeholder="blurred"
          className="content-card-logo d-flex justify-content-center rounded-3"
        />
      </Col>
      <Col md={6} lg={4} className="mt-4 px-5">
        <div className="d-flex justify-content-center justify-content-md-start">
          <StaticImage
            src="../../images/icon.png"
            alt="Logo"
            width={80}
            height={80}
            className="mb-2 mt-5 mt-md-0"
            placeholder="blurred"
          />
        </div>
        <h2 className="text-center text-md-start">{title}
          {symbol && <p className="position-absolute ms-2 d-inline" style={{fontSize: 'x-small'}}>{ symbol}</p>}
        </h2>
        {description && (
          <p className="text-center text-md-start">{description}</p>
        )}
      </Col>
    </Row>
  );
};

ContentCard.propTypes = propTypes;
ContentCard.defaultProps = defaultProps;

export default ContentCard;
